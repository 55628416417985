<template>
  <v-card
    :loading="loading"
    class="service-item bg-grey-gradient"
    dark
    @click="$emit('click', value)"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>
    <div class="service-type-img control" :style="getImageStyle()"></div>

    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div
      class="service-status"
      :class="{
        primary: value.status.code === 'PUBLISHED',
        'teal darken-1': value.status.code === 'PENDING',
      }"
    >
      {{ value.status.name }}
    </div>
    <div>
      <v-card-title class="service-title"
        >{{ value.code }}

        {{ value.name }}
      </v-card-title>

      <v-card-text>
        <v-row class="justify-space-between">
          <v-col cols="8">
            <v-row align="center" class="mx-0">
              <v-rating
                :value="4.5"
                color="amber"
                dense
                half-increments
                readonly
                size="14"
              ></v-rating>

              <div class="grey--text ms-4">4.5 (413)</div>
            </v-row>

            <div class="my-4 text-subtitle-1">
              {{ formatter.price(value.minPrice) }}-{{
                formatter.price(value.maxPrice)
              }}
            </div>

            <div class="">
              <p class="simple-text">{{ cutFormatter(value.short_description, 180) }}</p>
              <!-- <br /><br /> -->
              <!-- <h4 class="brief">{{ service.brief }}</h4> -->
            </div>
          </v-col>
          <v-col cols="4">
            <ServiceFitRateVue dark :value="value"></ServiceFitRateVue>
          </v-col>
        </v-row>
      </v-card-text>

      <div class="card-actions-bottom">
      <v-divider class="mx-4"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click.stop.prevent="onOpen">
            Learn More
          </v-btn>
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script>
import { FormatterHelper } from "../../../helpers/formatter.helper";
import ServiceFitRateVue from "../../atoms/service/ServiceFitRate.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      formatter: FormatterHelper,
      cutFormatter: FormatterHelper.cutString,
      loading: false,
      show: false,
    };
  },
  components: {
    ServiceFitRateVue,
  },
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },

    getImageStyle() {
      return {
        background: `url(` + this.value.type.image + ")",
        "background-size": "1200px 1200px",
        "background-position": "50% 50%",
        "background-repeat": "no-repeat",
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.service-item {
  z-index: 2;
  overflow: hidden;
  .service-type-img {
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    width: 30%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left right,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .service-title {
    text-transform: uppercase;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .service-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    text-transform: uppercase;
    text-align: center;
    top: 22px;
  }
}
</style>
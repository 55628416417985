<template>
  <div class="service-filters">
    <h2 class="white--text">FILTERS</h2>
    <HelpFormInputVue
      dark
      v-model="pSearch"
      :timeout="300"
      label="Search"
      :helpText="'Provide any text to search across services'"
      :tooltipAttrs="{
        left: true,
      }"
    ></HelpFormInputVue>
    <SelectorServiceTypeVue
      dark
      v-model="pTypeId"
      @onSearch="onServiceTypeSearch"
      :serviceTypes="serviceTypes"
      label="Select Service Types"
      :helpText="'You can select service types for easier filtering.'"
      multiple
      :tooltipAttrs="{
        left: true,
      }"
      autoload
    ></SelectorServiceTypeVue>
    <RangeInputVue
      v-model="pPriceRange"
      :timeout="300"
      :min="0"
      :max="priceRange.length - 1"
      :helpText="'You can select services price range for easier filtering.'"
      :labels="pPriceRangeLabels"
      step="1"
      ticks="always"
      :tooltipAttrs="{
        left: true,
      }"
    ></RangeInputVue>
  </div>
</template>


<script>
import { FormatterHelper } from "../../../helpers/formatter.helper";
import SelectorServiceTypeVue from "../../atoms/common/autocompletes/SelectorServiceType.vue";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";
import RangeInputVue from "../../atoms/common/inputs/RangeInput.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    serviceTypes: {
      type: Array,
      default: () => [],
    },
    priceRange: {
      type: Array,
      default: () => [0, 200000, 500000, 700000, 1000000, 1500000, 2000000],
    },
  },
  data() {
    const typeId = this.value.typeId
      ? Array.isArray(this.value.typeId)
        ? this.value.typeId.map((e) => parseInt(e))
        : [parseInt(this.value.typeId)]
      : [];

    let minIndex = 0;

    if (this.value.minPrice)
      minIndex = this.priceRange.findIndex((el) => el === this.value.minPrice);

    let maxIndex = this.priceRange.length-1;

    if (this.value.maxPrice)
      maxIndex = this.priceRange.findIndex((el) => el === this.value.maxPrice);

    return {
      formatter: FormatterHelper.price,

      pPriceRangeLabels: this.priceRange.map((e) => FormatterHelper.price(e)),
      pPriceRange: [minIndex, maxIndex],
      pSearch: this.value.search ? this.value.search : undefined,
      pTypeId: typeId,
    };
  },
  components: {
    SelectorServiceTypeVue,
    HelpFormInputVue,
    RangeInputVue,
  },

  watch: {
    value: {
      handler(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
    filters: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },

    search(newVal) {
      this.pSearch = newVal;
    },
    typeId(newVal) {
      this.pTypeId = newVal;
    },
    minPrice(newVal) {
      const index = this.priceRange.findIndex(
        (e) => parseInt(e) === parseInt(newVal)
      );

      if (index !== -1) this.pPriceRange = [index, this.pPriceRange[1]];
    },
    maxPrice(newVal) {
      const index = this.priceRange.findIndex(
        (e) => parseInt(e) === parseInt(newVal)
      );

      if (index !== -1) this.pPriceRange = [this.pPriceRange[0], index];
    },

    pSearch(newVal) {
      this.$emit("input", {
        search: newVal,
        typeId: this.pTypeId,
        minPrice: this.priceRange[this.pPriceRange[0]],
        maxPrice: this.priceRange[this.pPriceRange[1]],
      });
    },
    pTypeId(newVal) {
      this.$emit("input", {
        search: this.pSearch,
        typeId: newVal,
        minPrice: this.priceRange[this.pPriceRange[0]],
        maxPrice: this.priceRange[this.pPriceRange[1]],
      });
    },
    pPriceRange(newVal) {
      this.$emit("input", {
        search: this.pSearch,
        typeId: this.pTypeId,
        minPrice: this.priceRange[newVal[0]],
        maxPrice: this.priceRange[newVal[1]],
      });
    },
  },

  methods: {
    onServiceTypeSearch(search) {
      this.$emit("onServiceTypeSearch", search);
    },
    typeSelected(typeId) {
      this.pTypeId = typeId;
    },
  },
};
</script>


<style lang="scss" scoped>
.service-filters {
  // margin-right: 30px;
  padding: 40px;
  width: 100%;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AView',{attrs:{"value":_vm.value,"name":'Service List',"hasFilters":!!(_vm.filter.search || _vm.filter.typeId || _vm.filter.minPrice || _vm.filter.maxPrice)},on:{"onActivate":(e) => _vm.$emit('onActivate', e),"onMaximize":(e) => _vm.$emit('onMaximize', e),"onExpand":(e) => _vm.$emit('onExpand', e),"onCollapse":(e) => _vm.$emit('onCollapse', e),"onClose":(e) => _vm.$emit('onClose', e)},scopedSlots:_vm._u([{key:"content",fn:function({}){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('FormSection',{attrs:{"right":"","label":'Services List',"icon":_vm.value.view.display.icon,"actions":_vm.actions,"editable":!!_vm.actions.length,"payload":{
            from: _vm.value,
          }}})],1)],1),_c('v-row',{staticClass:"scroll-row d-flex flex-grow-1"},[_c('v-col',{staticClass:"pa-0 ma-0 d-flex flex-column flex-grow-1",attrs:{"cols":"12"}},[_c('v-virtual-scroll',{staticClass:"pa-0 ma-0",attrs:{"bench":5,"items":_vm.services,"height":"630","item-height":"370"},on:{"scroll":_vm.onScroll},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('ServiceItemVue',{staticClass:"ma-2",style:({
                height: '350px',
              }),attrs:{"value":item,"active":item.id === _vm.activeServiceId},on:{"onOpen":_vm.onSelect,"click":_vm.onSelect,"mouseover":_vm.onHover,"mouseleave":() => {}}})]}}],null,true)})],1)],1),_c('Pagination',{attrs:{"show":_vm.showMore,"value":_vm.pagination,"total":_vm.services.length},on:{"onLoadMore":function($event){return _vm.$store.dispatch('DeliverableStore/GetDeliverablesList', {
          next: true,
        })}}})]}},{key:"actions",fn:function({}){return [_c('ServiceFiltersVue',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
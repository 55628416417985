<template>
  <v-range-slider
    v-bind="$attrs"
    :value="value"
    @input="onInputChanged($event)"
    class="ma-0 pt-16"
    thumb-label="always"
    thumb-size="64"
    color="primary"
  >
    <v-tooltip v-bind="tooltipAttrs" slot="append">
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
          mdi-help
        </v-icon>
      </template>
      <span>{{ helpText }}</span>
    </v-tooltip>

    <template v-slot:thumb-label="props">
      {{ labels[props.value] }}
    </template>
  </v-range-slider>
</template>


<script>
export default {
  props: {
    value: {},
    labels: {
      type: Array,
      default: () => [],
    },
    helpText: {
      type: String,
      default: "",
    },
    formatter: {
      type: Function,
      default: (e) => e,
    },
    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
    timeout: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {};
  },

  methods: {
    season(val) {
      return this.icons[val];
    },
    defaultFormatter(val) {
      return val;
    },
    onInputChanged(event) {
      if (this.timeout && this.timeout !== 0) {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.$emit("input", event);
        }, this.timeout);
      } else {
        this.$emit("input", event);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .v-slider--horizontal {
  margin-left: 0 !important;
  margin-right: 0;
}
</style>